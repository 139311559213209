import { KeyValueTagStyled } from "./keyvaluetag.styles";
import { KeyValueTagProps } from "./keyvaluetag.types";

function KeyValueTag(props: KeyValueTagProps): JSX.Element {
  const { value, label } = props;
  return (
    <KeyValueTagStyled {...props}>
      <div className="key" title={label}>
        {label}:
      </div>
      <div className="value" title={value}>
        {value}
      </div>
    </KeyValueTagStyled>
  );
}

export default KeyValueTag;
